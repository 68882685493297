
const initialState = {
   
    queryParameters: {},
    

}


const rootReducer = (state = {
     queryParameters: {}
}, action) => {
    switch (action.type) {

        
        case 'SET_QUERY_PARAMTERS':
           
            return {
                ...state,
                queryParameters: { ...action.payload }
            }
      
        default:
            return state
    }
}


export default rootReducer;