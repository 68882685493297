import React, { useEffect } from 'react'

import { useLocation } from "react-router-dom";
import useTableScroll from "./useTableScroll"
import { useState, useRef, useCallback } from "react"
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useDownloadExcel } from 'react-export-table-to-excel';
import { downloadExcel } from "react-export-table-to-excel";
import useExcelExport from "./useExcelExport";
import Button from "../Components/Button";
import InputDateField from '../Components/InputDateField';
import moment from "moment";

export default function Report() {

  const [isButtonClicked, setIsButtonClicked] = useState(false)

   const { state } = useLocation();
   const queryParameters = useSelector((state) => state.queryParameters);
  const [startPageNumber, setStartPageNumber] = useState('1')
  const [endPageNumber, setEndPageNumber] = useState(process.env.REACT_APP_PAGE_NUMBER)
  const [searchInputName, setSearchInputName] = useState('')
  const [searchInputValue, setSearchInputValue] = useState('')
  const [reportFilterColumn, setReportFilterColumn] = useState({})
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const {loading, error, reportData, hasMore} = useTableScroll(state.reportData.id,startPageNumber, endPageNumber, state.reportData.query, searchInputName, searchInputValue,queryParameters,startDate,endDate)
const  { excelExport, execute } = useExcelExport(state.reportData.query, queryParameters)

  const [hideColumnNameList, setHideColumnNameList] = useState([])
const [isAccessible, setIsAccessible] = useState(false)
  const observer = useRef();
  const tableRef = useRef(null)
  
  const lastElementRef = useCallback(node=>{
    if(loading) return
    if(observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries =>{
      if(entries[0].isIntersecting && hasMore && searchInputName === ''){
        console.log('Visible')
        setStartPageNumber(parseInt(endPageNumber) +1)
        setEndPageNumber(parseInt(endPageNumber) + parseInt(process.env.REACT_APP_PAGE_NUMBER))
      }
    })
    if(node) observer.current.observe(node)
   // console.log(node)
  }, [loading, hasMore,searchInputName, startDate, endDate])



  useEffect(()=>{
        if(state === null){
            setIsAccessible(false)
        }
        else{
           
            setIsAccessible(true)
            getFilterColumnNames()
            getHideColumnsNames()
        }
    },[])



    const getFilterColumnNames = async()=>{
      await axios({
          method: 'get',
          url: process.env.REACT_APP_API_URL + '/GetFilterColumnNames?reportid=' + state.reportData.id,
          data: {},
          headers: {
              'database': process.env.REACT_APP_DATABASE_NAME,
              'Content-Type': 'application/json'
          },
      })
          .then(response => {
              if(response.data.length > 0){
                  setReportFilterColumn(response.data[0])
                  if(response.data[0].type === 'This Month'){
                    var date = new Date();
                    var firstDay = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('YYYY-MM-DD');
                    var lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format('YYYY-MM-DD');
                
                    setStartDate(firstDay)
                    setEndDate(lastDay)
                  }
                  else{
                   
                  }
                  var startDate = "";
                  var endDate = "";
                  var today = new Date();
                  if ((today.getMonth() + 1) <= 3) {
                    startDate = moment(new Date(today.getFullYear() - 1, 3, 1)).format('YYYY-MM-DD');
                    endDate = moment(new Date(today.getFullYear(), 2, 31)).format('YYYY-MM-DD');
                   // fiscalyear = (today.getDate() - 1) + "-" + today.getDate()
                  } else {
                    startDate = moment(new Date(today.getFullYear(), 3, 1)).format('YYYY-MM-DD');
                    endDate = moment(new Date(today.getFullYear() +1, 2, 31)).format('YYYY-MM-DD');
                   
                  }
                  setStartDate(startDate)
                  setEndDate(endDate)
                 
                 
              }
              else{
                
                  setReportFilterColumn({})
                  
              }
              
          })
          .catch(err => {
              // Handle errors
              console.error(err);
          });
  }


  // get hide columns names from the report id
  const getHideColumnsNames = async () => {
        await axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/GetColumnNames?reportid=' + state.reportData.id,
            data: {},
            headers: {
                'database': process.env.REACT_APP_DATABASE_NAME,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                response.data.map((data, index) => {
                    hideColumnNameList.push(data.columnname)
                })
                
            })
            .catch(err => {
                // Handle errors
                console.error(err);
            });
    }


 
    const handleSearchInput = (event) =>{
     
    //  console.log('inside oncgange')
 if(event.target.value === ''){
   setStartPageNumber('1')
      setEndPageNumber(process.env.REACT_APP_PAGE_NUMBER)
            setSearchInputName('')
        setSearchInputValue('')
        
        return
        }
        
        setSearchInputName(event.target.id)
        setSearchInputValue(event.target.value)
    }

 
      const btnClick = ()=>{
       execute()
      }

      const handleStartDateChange = value =>{
    
        const newDate = moment(new Date(value)).format('YYYY-MM-DD');
        setStartDate(newDate);
      }

      const handleEndDateChange = value =>{
        const newDate = moment(new Date(value)).format('YYYY-MM-DD');
        setEndDate(newDate);
        setStartPageNumber('1')
        setEndPageNumber(process.env.REACT_APP_PAGE_NUMBER)
      }

      const handleDeleteClick = async(data)=>{
        
      }

  return (
    isAccessible === true ? <>
    <div className="overflow-auto h-60">
    <Button handleClick={btnClick} btnName="Excel Export"></Button>
    <InputDateField value={startDate} handleOnChange={handleStartDateChange}></InputDateField>
    <InputDateField value={endDate} handleOnChange={handleEndDateChange}></InputDateField>
      {/* <button onClick={btnClick}>Excel export</button> */}
      <table id="table" ref={tableRef} className="table-auto bg-[white] rounded-md shadow-md w-full">
        <thead className="sticky -top-1 order-1 border-solid border-gray-400 text-sm capitalize border-2 text-slate-100" style={{ background: "rgb(54, 128, 171)" }}>
  
           <tr>
           <th  scope="col" className={'px-5 py-2 border-[1px] border-solid border-gray-400 w-[100px]'}>Actions</th>
              {
                  reportData.length > 0 ? reportData[0].map((data, index) =>
                      {
                        if(hideColumnNameList.indexOf(data.key) >= 0){
                         return null
                        }
                        else{
                          return <th key={index} scope="col" className={'px-5 py-2 border-[1px] border-solid border-gray-400 w-[100px]'}>{data.key}</th>
                        }
                         
                      }
                  ) : null
              }
            </tr>
             <tr className="bg-white">
                            {
                                    reportData.length > 0 ? reportData[0].map((data,index)=>{
                                      if(hideColumnNameList.indexOf(data.key) < 0){
                                        return  <th className="p-1 px-2 border-1 border-solid border-gray-400">
                                        <input id={data.key} type="text" className="px-1 text-[14px] w-16 border-2 border-solid border-gray-600 rounded text-black font-normal" onChange={handleSearchInput} />
                                    </th>
                                      }
                                    }
                                   
                                    ):null
                                }
                            </tr>
        </thead>
        <tbody>
          {
            reportData.length > 0 ? reportData.map((data, index) =>{
              if(reportData.length === index +1){
           
                          return <tr ref={lastElementRef} key={index}>
                            <td key = {index} className="px-5 py-2 border-[1px] border-solid border-gray-400 w-[100px]" >
                              <button onClick={()=>handleDeleteClick(data)}>Delete</button>
                            </td>
                            
                    {
                      
                        data.map((data1, index1) =>{
                          if(hideColumnNameList.indexOf(data1.key) < 0){
                            return <td key = {index1} className="px-5 py-2 border-[1px] border-solid border-gray-400 w-[100px]" >{data1.value}</td>
                          }
                        }
                            
                        )
                    }

                </tr>
               
                 
              }
              else{
               
              return <tr key={index}>
                <td key = {index} className="px-5 py-2 border-[1px] border-solid border-gray-400 w-[100px]" >
                              <button onClick={()=>handleDeleteClick(data)}>Delete</button>
                            </td>
                    {
                        data.map((data1, index1) =>{
                          if(hideColumnNameList.indexOf(data1.key) < 0){
                            return <td  key = {index1} className="px-5 py-2 border-[1px] border-solid border-gray-400 w-[100px]" >{data1.value}</td>
                          }
                        }
                            
                        )
                    }

                </tr>
                       
                
              }
            }
               


            ) : null
          }
        </tbody>
      </table>
      </div>
      <div>{loading && 'Loading..'}</div>
      <div>{error && 'Error..'}</div>
    </>:null
  )
}
