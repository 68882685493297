import React from "react";

function BackButton({handleClick, btnName}){
    return(
        <button  className="Button_Back_Style" onClick={handleClick}>{btnName}</button>
        
    )
    
}

export default BackButton;