import logo from './logo.svg';
import './App.css';
import ReportsList from './Reports/ReportsList';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Report from './Reports/Report';
import NewReport from './DynamicReport/Report';


import { createStore } from "redux"
import { Provider } from "react-redux"
import rootReducer from './store/rootReducer.js'
import Dashboard from './Dashboard/Dashboard.js';
import { ErrorMessage } from './Components/ErrorMessage.js';

function App() {
   const store = createStore(rootReducer)
  return (
    <>
    
     <Provider store={store}>
    <BrowserRouter>
       <Routes>
        
          <Route path="/dashboard/:database/:locationid/:userid/:userrole/:username/:companyid/:reportname/:inventorydatabase/:partyid/:fyear/:pagename/:cssfilename/:reportheight" element={<Dashboard></Dashboard>}></Route>
          <Route path="/Reports-Names-List/:database/:locationid/:userid/:userrole/:username/:companyid" element={<ReportsList/>} />
          {/* <Route path="/Report" element={<Report/>} /> */}
          <Route path="/Report" element={<Report/>} />

          <Route path="/New-Report" element={<NewReport/>} />
          <Route path="/Error-Massage-Page" element={<ErrorMessage/>} />
       </Routes>
       </BrowserRouter>
</Provider>


    </>
  );
}

export default App;
