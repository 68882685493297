import axios from "axios"
import {useEffect, useState} from 'react'

function useTableScroll (reportInfo,startPageNumber, endPageNumber, inputName,queryParameters, startDate, endDate, isSuccess, isLastVisibile, isDelete, checkedSrNo){

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [reportData, setReportData] = useState([])
    const [reportStartData, setReportStartData] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [isInputChange, setIsInputChange] = useState(inputName)
    const [isAuthorized, setIsAuthorized] = useState(false)

    useEffect(()=>{
       
        if(JSON.stringify(reportInfo) !== "{}" || isSuccess === true){
            
            fetchReportData()
        } 
    },[isLastVisibile, inputName,startDate,endDate, reportInfo, isSuccess, isDelete])

   
    useEffect(()=>{
        if(inputName==='' || isSuccess === true){
            
          setReportData([])
        }
    },[inputName,startDate, endDate, isSuccess])

    useEffect(()=>{
        if(inputName === ''){
            setIsInputChange('')
        }
    },[inputName])


    useEffect(()=>{
        setReportData([])
    },[isDelete])

    
  


    const fetchReportData = async()=>{
      console.log('inside fetch report function',{
        '@reportid':reportInfo.id.toString(),
        '@startPageNumber':startPageNumber.toString(),
        '@endPageNumber': endPageNumber.toString(),
        '@filterName': inputName,
        '@filterValue': '',
        '@startDate': startDate,
        '@endDate': endDate,
        ...queryParameters
        
    })
       setLoading(true)
       setError(false)
        await axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/ExecuteReportQuery',
            data: {
                query:reportInfo.query,
                parameters:{
                    '@reportid':reportInfo.id.toString(),
                    '@startPageNumber':startPageNumber.toString(),
                    '@endPageNumber': endPageNumber.toString(),
                    '@filterName': inputName,
                    '@filterValue': checkedSrNo.toString(),
                    '@startDate': startDate,
                    '@endDate': endDate,
                    ...queryParameters
                    
                },
            },
            headers: {
                'database': queryParameters['@inventorydatabase'],
                'Content-Type': 'application/json'
            },
        })
        .then(response => {
            console.log(response)
            setLoading(false)
            setError(false)
            if( response.data.responseCode === "401"){
                setIsAuthorized(true)
                return;
            }
            const arr = response.data.mData[0];
           console.log('get 0 index = ',arr)
            if(arr[0].key === 'Sr No' && arr[0].value===''){
                console.log('isnide its blank')
                if(inputName !== ''){
                    console.log('change value 1',inputName, isInputChange)
                    if(inputName === isInputChange){
                        
                        console.log('change value 2')
                        setHasMore(false)
                        
                        // setReportData(prev =>{
                        //     return [...prev , ...response.data.mData]})
                    }
                    else{
                        console.log('change value 3')
                        setReportStartData([...response.data.mData[0]])
                        setReportData([...response.data.mData])
                        setHasMore(false)
                    }
                    
                    //setReportStartData([...response.data.mData[0]])
                    //setHasMore(false)
                    //setReportData([...response.data.mData])
                }
                else{
                    console.log('isnide its blank -1 ')
                    //setReportData([])
                    setReportStartData([...response.data.mData[0]])
                    setHasMore(false)
                }
              
            }
            else{
                console.log('isnide its not blank')
              if(inputName !== ''){
               
                    setReportStartData([...response.data.mData[0]])
                    console.log('change value',inputName, isInputChange)
                    if(inputName === isInputChange){
                        console.log('change value 4')
                        const arr = reportData[0];
                        if(arr[0].key === 'Sr No' && arr[0].value===''){
                            setReportData([...response.data.mData])
                        }
                        else{
                            setReportData(prev =>{
                                return [...prev , ...response.data.mData]})
                        }
                        
                    }
                    else{
                        console.log('change value 5')
                        setReportData([...response.data.mData])
                        setIsInputChange(inputName)
                    }
                    
                    setHasMore(true)
              }
              else{
                setReportStartData([...response.data.mData[0]])
                    if(arr[0].key === 'Sr No' && arr[0].value===''){
                        setReportData([...response.data.mData])
                    }
                    else{
                        setReportData(prev =>{
                            return [...prev , ...response.data.mData]})
                    }
                    console.log('change value 6')
                    
               
                }
              setHasMore(true)
            }
        })
        .catch(err => {
            setLoading(false)
            setError(true)
            console.error(err);
        });
    }

    return {loading, error, reportData, hasMore, reportStartData, isAuthorized };
}

export default useTableScroll;