import React, { useEffect, useState } from 'react'
import axios from "axios"

export default function usePdfExport(isButtonClick, apiName, reportInfo,queryParameters, inputName,disablePdfExport, startDate, endDate) {

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(()=>{
    
    if(isButtonClick === true){
      //exportExcel
      pdfExport()
    }
  },[isButtonClick])

  const pdfExport = async()=>{
      setIsLoading(true)
      setIsError(false)
      await axios({
          method: 'post',
          url: process.env.REACT_APP_API_URL + '/'+ apiName,
          data: {
              query:reportInfo.query,
              parameters:{
                  '@reportid':reportInfo.id.toString(),
                  '@startPageNumber':'',
                  '@endPageNumber': '',
                  '@filterName': inputName,
                  '@filterValue': '',
                  '@startDate': startDate,
                  '@endDate': endDate,
                  ...queryParameters
                  
              },
          },
          headers: {
              'database': queryParameters['@inventorydatabase'],
              'Content-Type': 'application/json'
          },
        responseType: "blob"
      })
      .then(response => {
         setIsLoading(false)
          setIsError(false)
          disablePdfExport(false) 
          console.log(response)
            const pdfBlob = new Blob([response.data], { type: "application/pdf" });
    
            // Create a temporary URL for the Blob
            const url = window.URL.createObjectURL(pdfBlob);
    
            // Create a temporary <a> element to trigger the download
            const tempLink = document.createElement("a");
            tempLink.href = url;
            tempLink.setAttribute(
              "download",
               apiName +'.pdf'
            ); // Set the desired filename for the downloaded file
    
            // Append the <a> element to the body and click it to trigger the download
            document.body.appendChild(tempLink);
            tempLink.click();
    
            // Clean up the temporary elements and URL
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(url);
      })
      .catch(err => {
         setIsLoading(false)
      setIsError(true)
          console.error(err);
      });
    }


  return { isLoading, isError };
}
