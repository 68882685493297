import React, { useEffect, useState } from "react";

import right from '../Image/Right.png';
import wrong from '../Image/Cross.png'

function ConfirmationModal(props){
    const [isOpen, setIsOpen]= useState(props.open)
    useEffect(()=>{
        setIsOpen(props.open)
    },[props.open])
    const handleClick = ()=>{
        setIsOpen(false)
        props.setModalState();
    }
    return(
    <>
    {isOpen === true ?<div  className="Modal_Header_Style"></div>:null}
        {isOpen === true ? <div id="successModal" tabindex="-1" aria-hidden="true" class="Header_One">
        <div class="Header_Box">
            
            {/* <div class="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5"> */}
                <button type="button" class="Modal_Close_Button" data-modal-toggle="successModal" onClick={handleClick}>
                    {/* <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg> */}
                    <i class="fa-solid fa-circle-xmark Close_Icon" >Close</i>
                </button>
                <div class="Svg_Img">
                    {props.success ? <img src={right}/>: <img src={wrong}/>}
                   
                </div>
                <p class="Modal_Msg">{props.message}</p>
                <div>
                    <button class="Modal_Yes_Btn" onClick={props.onClickYes}>Yes</button>
                    <button class="Modal_No_Btn" onClick={props.onClickNo}>No</button>
                </div>
            
        </div>
    </div>:null}
    </>
    )
}

export default ConfirmationModal;