import React from "react";

function InputTextField({handleOnChange, value}){
   

    return(
        <input className="border-solid border-[1px] border-gray-600 rounded pl-2 m-1" onChange={(event)=>handleOnChange(event.target.value)} value={value}></input>
    )
}

export default InputTextField;