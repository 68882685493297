const setQueryParamters = (obj) => {
    return {
        type: "SET_QUERY_PARAMTERS",
        payload: obj
    }
}


export default {
    
    setQueryParamters,
    
}
