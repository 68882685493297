import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import actions from '../store/actions'

const Dashboard = ()=>{

    const params = new useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(actions.setQueryParamters({'@database': params.database, '@locationid': params.locationid, '@userid':params.userid, '@userrole':params.userrole,'@username':params.username, '@companyid':params.companyid, '@reportname':params.reportname, '@inventorydatabase': params.inventorydatabase,'@partyid':params.partyid,'@fyear':params.fyear,'@pagename':params.pagename,'@cssfilename':params.cssfilename,'@reportheight':params.reportheight}))
        
        navigate("/New-Report");
       
    }, [])

    return(
        <>
        </>
    )
}

export default Dashboard;