import React, {useState, useEffect} from "react";
// import './AutoCompleteExtender.css'
import Autocomplete from "react-autocomplete";
import axios from "axios";
function AutoCompleteExtender({itemsList, placeholder,defaultValue,setColumnName}){
    const [tempItemList, setTempItemList] = useState(itemsList)
    const [value, setValue] = useState(defaultValue)
    const [showDropdown, setShowDropdown] = useState(false)
    console.log('value', defaultValue)
    const handleMenuVisibility = (isOpen)=>{
        console.log('inside menu visibility')
        if(isOpen === true){
            setShowDropdown(true)
        }
        if(isOpen===false){
           // props.setId('')
            setValue('')
            
        }
    }

    const handleOnSelect = (value) =>{
      console.log('inside on select')
        itemsList.map((item, index)=>{
            if(item.code === value){
           
               // props.setId(item.id)
            }
        })
        setValue(value)
        setColumnName(value)
        setShowDropdown(false)
    }

    const handleOnChange = (val) =>{
        console.log('inside on change')
        setValue(val)
        if(val === ''){
            setTempItemList([...itemsList])
            return
        }
        else{
            const arr = [];
            itemsList.map((data,index)=>{
                if(data.toLowerCase().includes(val)){
                    console.log('inside temp')
                    arr.push(data)
                    }
            })
            setTempItemList([...arr])
        }
    }

    const handleFocus = ()=>{
        console.log('inside on focus')
       
        
    }
    return(
            <Autocomplete 
                value={value}
                items={tempItemList}
                //  shouldItemRender={handleShouldItemRender}
                getItemValue={item=>item}
                onChange={e => {
                    handleOnChange(e.target.value)
                    }}
                onSelect={(val) => {handleOnSelect(val)}}
                renderItem={(item, isHighlighted) =>
                    // Styling to highlight selected item
                    <div key={item} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                    {item}
                  </div>
                }
                onMenuVisibilityChange={(isOpen)=>{
                    handleMenuVisibility(isOpen)
                }}
                
                inputProps={{
                    style: {
                        padding:'0.25rem',
                        fontSize:'12px',
                        borderColor: 'black',
                        border:'1px black solid',
                        borderRadius:'2px',
                        
                    },
                    placeholder: placeholder,
                    onFocus: handleFocus,
                    
                }}     
                menuStyle={{
                    borderRadius: '3px',
                    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                    background: 'rgba(255, 255, 255, 0.9)',
                    padding: '2px 0',
                    fontSize: '90%',
                    position: 'fixed',
                    overflow: 'auto',
                    maxHeight: '50%', 
                    zIndex:'999'// TODO: don't cheat, let it flow to the bottom
                  }}
                
                >

            </Autocomplete>

    )
}

export default AutoCompleteExtender