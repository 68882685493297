import axios from "axios"
import {useEffect, useState} from 'react'


export default function useTableScroll(reportid,startPageNumber, endPageNumber, spName, inputName, inputValue,queryParameters, startDate, endDate) {

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [reportData, setReportData] = useState([])
  const [excelReportData, setExcelReportData] = useState([])
  const [hasMore, setHasMore] = useState(false)

  useEffect(()=>{
    
    fetchReportQuery()



  },[startPageNumber, endPageNumber, inputName, inputValue,endDate])

  useEffect(()=>{
    if(inputName===''){
      setReportData([])
    }
  },[inputName, endDate])



   const fetchReportQuery = async() =>{
    setLoading(true)
    setError(false)
        await axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/OpenReport',
            data: {

                query:spName,
                parameters:{
                    '@reportid':reportid.toString(),
                     '@startPageNumber':startPageNumber.toString(),
                    '@endPageNumber': endPageNumber.toString(),
                     '@filterName': inputName,
                '@filterValue': inputValue,
                '@startDate': startDate,
                '@endDate': endDate,
                 ...queryParameters
                    
                },
               
            },
            headers: {
                'database': process.env.REACT_APP_DATABASE_NAME,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                console.log('query ', response.data.queryExec)
                //getCheckedColumnsNames();
                execSp(response.data.queryExec)
               

            })
            .catch(err => {
                // Handle errors
                console.error(err);
            });
    
    }


    const execSp =(spQuery)=>{    
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL + '/ExecuteQuery',
      data: {
        query: spQuery
      }, 
      headers: {
        'database': process.env.REACT_APP_DATABASE_NAME,
        'Content-Type': 'application/json'
      },
    })
    .then(response => {
    //  console.log(response)
       setLoading(false)
      setError(false)
      const arr = response.data.mData[0];
      if(arr[0].key === 'RowNum' && arr[0].value===''){
    //   console.log('inside here 3')
        setHasMore(false)
        
        //console.log(response.data)
      }
      else{
        if(inputValue !== ''){
       //  console.log('inside here 2')
          setReportData([...response.data.mData])
        
        }
        else{
          // console.log('inside here 1')
        setReportData(prev =>{
          return [...prev , ...response.data.mData]})
          
        }
       
        setHasMore(true)
       // console.log(response.data)
      }
     
     
     
     
    })
    .catch(err => {
      setError(true)
      setLoading(false)
   //   console.error(err);
    });
    }

  return {loading, error, reportData, hasMore}
}
