import axios from "axios";
import React, { useEffect, useState, useRef, useCallback, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import useTableScroll from "./useTableScroll";
import Button from "../Components/Button";
import useExcelExport from "../ExcelExport/useExcelExport";
import usePdfExport from "../PdfExport/usePdfExport";
import Loader from "../Components/Loader";
import Modal from "../Components/Model";
import BackButton from "../Components/BackButton.js";
import ColumnResizer from "react-table-column-resizer";
import ResetButton from "../Components/RestButton";
import { ErrorMessage } from "../Components/ErrorMessage";
import CheckBox from "../Components/CheckBox";
import ConfirmationModal from "../Components/ConfirmationModal";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

const Report = () => {

    const queryParameters = useSelector((state) => state.queryParameters);
    const navigate = useNavigate();
  

    //loading
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [statusMessage, setStatusMessage] = useState('')

    //button click
    const [isExcelExportClick, setIsExcelExport] = useState(false)
    const [isPdfExportClick, setIsPdfExport] = useState(false)

    //report data
    const [reportInfo, setReportInfo] = useState({})
    const [hideColumnNameList, setHideColumnNameList] = useState([])
    const [dateFilterColumn, setDateFilterColumn] = useState({})
    const [reportActionsNames, setReportActionsNames] = useState([])
    const [reportActionsJs, setReportsJs] = useState([])
    const [reportTotalCountColumns, setReportsTotalCountColumns] = useState([])
    const [reportTotalColumnsData, setTotalColumnsData] = useState('')
    const [reportColumnType, setReportColumnType] = useState({})

    const [startPageNumber, setStartPageNumber] = useState('1')
    const [endPageNumber, setEndPageNumber] = useState(process.env.REACT_APP_PAGE_NUMBER)
    const [isLastVisibile, setIsLastVisible] = useState(false)
    const [incre, setincre] = useState(1);
    const [isDelete, setIsDelete] = useState(incre)
 //   const [searchInputName, setSearchInputName] = useState('')
  //  const [searchInputValue, setSearchInputValue] = useState('')
    const [searchInputQuery, setSearchInputQuery] = useState('')
    const [searchInputObject, setSearchInputObject] = useState([])
    const [startDate, setStartDate] = useState(sessionStorage.getItem('startDate') ? sessionStorage.getItem('startDate') :queryParameters['@fyear'])
    const [endDate, setEndDate] = useState(sessionStorage.getItem('endDate') ? sessionStorage.getItem('endDate') :queryParameters['@locationid'])

    // checkbox states
    const [isAllChecked, setIsAllChecked] = useState(false)
    const [checkedData, setCheckedData] = useState([])
    const [checkedSrNo, setCheckedSrNo] = useState([])

    //modal states
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("")
    const [isSuccess, setIsSuccess] = useState(false)

    //confirmation modal states
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState("")
  const [isConfirmationSuccess, setIsConfirmationSuccess] = useState(false)

  //FRom and to date
  const [fromDataValue, setFromDateValue] = useState()
  const [toDateValue, setToDateValue] = useState()

    const disableExcelExport = () => {
        setIsExcelExport(false)
    }

    const disablePdfExport = () => {
        setIsPdfExport(false)
    }

    const handleModalState = () => {
        setShowModal(false)

    }

    //Table data with scroll
    const { loading, error, reportData, hasMore, reportStartData, isAuthorized } = useTableScroll(reportInfo, startPageNumber, endPageNumber, searchInputQuery, queryParameters, startDate, endDate, isSuccess, isLastVisibile, isDelete, checkedSrNo, fromDataValue, toDateValue)

    //Excel Export
    const { loadingExcel, errorExcel } = useExcelExport(isExcelExportClick, queryParameters['@reportname'] + '_excel', reportInfo, startPageNumber, endPageNumber, searchInputQuery,queryParameters, startDate, endDate, disableExcelExport)

    //Pdf Export
    const { loadingPdf, errorPdf } = usePdfExport(isPdfExportClick, queryParameters['@reportname'] + '_pdf', reportInfo, queryParameters, searchInputQuery,disablePdfExport, startDate, endDate,)

    //Scroll Click listener to the end
    const observer = useRef();
    const lastElementRef = useCallback(node => {

        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setincre(incre +10)
                setStartPageNumber(parseInt(endPageNumber) + 1)
                setEndPageNumber(parseInt(endPageNumber) + parseInt(process.env.REACT_APP_PAGE_NUMBER))
                setIsLastVisible(incre)
               
            }
           
        })
        if (node) observer.current.observe(node)
    }, [loading, hasMore, searchInputQuery, startDate, endDate])


    let arr = [];

    useEffect(() => {
        
        if (JSON.stringify(queryParameters) !== "{}") {
            document.getElementById("Table_div").style.height  = queryParameters["@reportheight"];
            fetchReportOpeningData()
        }

    }, [queryParameters])


    const fetchReportOpeningData = async () => {
        setStatusMessage('Fetching Data..')
        setIsLoading(true)
        setIsError(false)
        await axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/ReportOpeningData?reportname=' + queryParameters['@reportname'],
            data: {},
            headers: {
                'database': queryParameters['@inventorydatabase'],
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
              //  console.log(response)
                setIsLoading(false)
                if (response.data.responseCode === "200") {
                    setReportInfo({...response.data.reportInfo})
                    setHideColumnNameList([...response.data.hideColumnsList])
                    setDateFilterColumn({...response.data.filterColumn})
                    setReportActionsNames([...response.data.reportActionsList])
                    setReportsJs({...response.data.reportActionsJs})
                    setReportsTotalCountColumns([...response.data.reportTotalColumnName])
                    setReportColumnType({...response.data.reportColumnType})
                }
                else {
                    setStatusMessage(response.data.responseMessage)
                }

            })
            .catch((err) => {
                setIsLoading(false)
                setIsError(true)
                setStatusMessage(err)

            })

    }

    useEffect(()=>{
        //console.log(reportTotalCountColumns)
        if(reportTotalCountColumns.length > 0){
            fetchTotalCount();
        }
    },[reportTotalCountColumns,startDate,endDate])

    const fetchTotalCount = async() => {
    
    //    console.log({
    //             'colourname':queryParameters['@locationid'],
    //             'productid':queryParameters['@partyid'],
    //             'date':queryParameters['@fyear'],
    //             'pagename':queryParameters['@pagename'],
    //             'companyid':queryParameters['@companyid'],
    //             'fields':reportTotalCountColumns
    //         })
        await axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/GetTotalFooterForReport',
            data: {
                'fields':reportTotalCountColumns,
                parameters : {
                '@colourname':queryParameters['@locationid'],
                '@productid':queryParameters['@partyid'],
                '@date':queryParameters['@fyear'],
                'pagename':queryParameters['@pagename'],
                '@companyid':queryParameters['@companyid'],
                '@startDate':startDate,
                '@endDate':endDate,
                ...queryParameters
                }
                
                
            },
            headers: {
                'database': queryParameters['@inventorydatabase'],
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
              // console.log('result',response)
               const result = response.data.data
             //  console.log('result',result)
               let str = '';
               for (const key in result) {
                if (result.hasOwnProperty(key)) {
                    str = str +  ' '+  key + ' : ' + result[key]  
                  
                }
                }
               setTotalColumnsData({...result})

            })
            .catch((err) => {
                

            })

    }

    useEffect(()=>{
        if(searchInputObject.length === 0){
            setSearchInputQuery('')
        }
        else{
            let whereClause = '';
            searchInputObject.map((data,index)=>{
                if(index === 0){
                    whereClause = whereClause + 'where ['+ data.id+'] like \'\'%'+ data.value+'%\'\'';
                   
                }
                else{
                    whereClause = whereClause + ' and ['+ data.id+'] like \'\'%'+ data.value+'%\'\'';
                    
                }
            })
            setSearchInputQuery(whereClause)
        }
    },[searchInputObject])

    useEffect(()=>{
       // console.log('query = ', searchInputQuery)
    },[searchInputQuery])

    const handleSearchInput = (event) => {
        //console.log('inside handle input change')
        setStartPageNumber('1')
        setEndPageNumber(process.env.REACT_APP_PAGE_NUMBER)
        if(searchInputObject.length === 0){
          //  console.log('inside if statement')
            setSearchInputObject([{ id: event.target.id, value: event.target.value.trim()}])
        }
        else{
         //   console.log('inside else statement')
            if (event.target.value === ''){
                setSearchInputObject(prev => prev.filter((data)=> data.id != event.target.id ))
            }
            else {
                const arr = searchInputObject.filter((data)=> data.id != event.target.id );
                setSearchInputObject([...arr, { id: event.target.id, value: event.target.value.trim()}])
            }
           
        }
        
       

    }

    const handleExcelExportClick = () => {
        setIsExcelExport(true)

    }

    const handlePdfExportClick = () => {
        setIsPdfExport(true)
    }

    const [ImportedComponent, setImportedComponent] = useState(null);
    useEffect(() => {
      //  console.log(reportActionsNames.length)
        if (reportActionsNames.length > 0) {
            var result = reportActionsNames.every(elem => ['Total','Back','Excel','Pdf'].includes(elem))
           
            if(result){

            }
            else{
           // console.log(reportActionsNames.length)
            importComponent()
            }
            
        }

    }, [reportActionsNames])

    // const importComponent = async (reportName) => {
    //     try{
    //         const module = await import('../ReportActions/' + reportName);
            
    //         const Actions = module.default;
            
    //         setImportedComponent(() => Actions);
    //     }
    //     catch(e){

    //     }

    // };

    const importComponent = async () => {
        try{
            const module = await import('../ReportActions/Actions.js');
            
            const Actions = module.default;
            
            setImportedComponent(() => Actions);
        }
        catch(e){

        }

    };

    const handleCloseModal = ()=>{
        setStartPageNumber('1')
        setEndPageNumber(process.env.REACT_APP_PAGE_NUMBER)
        setSearchInputObject([])
        setSearchInputQuery('')
        setincre(incre +1)
        setIsDelete(incre +1)
    }

    const handleSuccess = (message, isSuccess) => {
     
        setShowModal(true)

        if (isSuccess === 'success') {
            Array.from(document.querySelectorAll("input")).forEach(
                input => (input.value = "")
              );
            setStartPageNumber('1')
            setEndPageNumber(process.env.REACT_APP_PAGE_NUMBER)
            setSearchInputObject([])
            setSearchInputQuery('')
            setincre(incre +1)
            setIsDelete(incre)
            setIsSuccess(true)
        }
        else {
           // setIsSuccess(false)
        }

        setModalMessage(message)
    }



    const handleChangeWidth = (item, width) => {
       // console.log('inside handle width')
        localStorage.setItem(item, width)
        // var inputField = document.getElementById(item);
        // inputField.style.width = width + "px";



    }
  
      
   
    const handleResetClick = () =>{
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
          );
          setStartPageNumber('1')
        setEndPageNumber(process.env.REACT_APP_PAGE_NUMBER)
          setSearchInputObject([])
          setSearchInputQuery('')
    }

    const handleKeyboard = ({ repeat, altKey, ctrlKey, shiftKey , key}) => {
        if (repeat) return
      
        // Handle both, `ctrl` and `meta`.
        if ((ctrlKey) && altKey && shiftKey && key === 'Enter') {
           // console.log('ctrl + /')
            window.open("https://inhousereactapp.macobiz.net/Reports-Names-List/" + queryParameters['@inventorydatabase'] + "/" + queryParameters['@locationid'] + "/" + queryParameters['@userid'] + "/" + queryParameters['@userrole'] + "/" + queryParameters['@username'] + "/" + queryParameters['@companyid'], "_blank");
            //navigate("/Reports-Names-List/" + queryParameters['@inventorydatabase'] + "/" + queryParameters['@locationid'] + "/" + queryParameters['@userid'] + "/" + queryParameters['@userrole'] + "/" + queryParameters['@username'] + "/" + queryParameters['@companyid'])
            //navigate("/Reports-Names-List/inventory_aananda_macotest/1/36/Main/macotest/1");
        }
      }

    useEffect(() => {

       ImportCss()
        document.addEventListener('keydown', handleKeyboard)

        // Important to remove the listeners.
        return () => document.removeEventListener('keydown', handleKeyboard)
     
      }, []);

      const ImportCss = async()=>{
            const module = await import('../Components/'+ queryParameters['@cssfilename']);
      }

    const handleAllChecked = ()=>{
        if(!isAllChecked === true){
           
            
            let arr = [];
            reportData.map((data,value)=>{
                arr.push(data[0].value)
               // console.log(data[0].value)
                //console.log(document.getElementById(data[0].value))
              //  document.getElementById(data[0].value).checked = true
            })

            setCheckedSrNo([...arr])
            setCheckedData(reportData)
           
        }
        else{
              
             setCheckedSrNo([])
            setCheckedData([])
        }
        setIsAllChecked(!isAllChecked)
        
        
    }

    useEffect(()=>{

        if(isAllChecked === true){
            let arr = [];
            reportData.map((data,value)=>{
                arr.push(getAssetId(data))
                
            })

            setCheckedSrNo([...arr])
            setCheckedData(reportData)
        }
        else{
            // setCheckedSrNo([])
            // setCheckedData([])
              
           
        }
        
    },[reportData])

    const handleSingleCheckboxChange = (event,data) =>{
        setIsAllChecked(false)
      //  console.log('checked value',event.target.checked)
       // console.log(data)
        isChecked(data)
    }

    const isChecked = (data)=>{
       // console.log(data[0].value)
        const assetId = getAssetId(data);
        let isPresent = false;
        let arr = [];
        let arr1 = [];
        if(checkedData.length > 0 ){
            checkedData.map((data1,key)=>{
               
                const isAssetId = getAssetId(data1)
                if(assetId === isAssetId){
                   // console.log(data1)
                   // console.log(document.getElementById(data[0].value))
                    //document.getElementById(data[0].value).checked = false
                    isPresent = true;
                }
                else{
                    arr1.push(isAssetId)
                   // document.getElementById(data[0].value).checked = true
                    //console.log(document.getElementById(data[0].value))
                    arr.push(data1)
                }
               // console.log(data1[0].value, data[0].value, getAssetId(data) === getAssetId(data1))
            })
            if(isPresent === false){
                    arr1.push(assetId)

              //  document.getElementById(data[0].value).checked = true
               // console.log(document.getElementById(data[0].value))
                arr.push(data)

            }
        }
        else{
                    arr1.push(assetId)

             //document.getElementById(data[0].value).checked = true
            // console.log(document.getElementById(data[0].value))
            arr.push(data)
            setCheckedData([...arr])
        }
       
       setCheckedSrNo([...arr1])
        setCheckedData([...arr])
       // console.log(arr)
       // console.log(checkedData)
    }

  const handleBulkDeleteClick = ()=>{
    setShowConfirmationModal(true)
    setConfirmationModalMessage('Are you sure you want to delete ?')
  }

  const bulkDelete = async()=>{
    await axios({
          method: 'post',
          url: process.env.REACT_APP_API_URL + '/'+ reportInfo.name + '_bulk_delete',
          data: {
               
                "mReportData" : checkedData,
                
                "parameters":{
                    '@selectAll': isAllChecked.toString(),
                  '@filterName': searchInputQuery,
                 
                  ...queryParameters
                  
              },
          },
          headers: {
              'database': queryParameters['@inventorydatabase'],
              'Content-Type': 'application/json'
          },
        
      })
      .then(response => {
        setShowConfirmationModal(false)
        if (response.status === 200) {
            setIsAllChecked(false)
            setCheckedData([])
            setCheckedSrNo([])
           // console.log('inside')
            //console.log(response)
            handleSuccess(response.data, 'success')
          }
          else {
           // console.log('inside here')
            handleSuccess(response.data,'failure' )
           // console.log(response)
            //onSuccess()
          }
         
      })
      .catch(err => {
        setShowConfirmationModal(false)
        handleSuccess(err.response.data, 'failure')
      });
  }
   
  const handleYesClick = ()=>{
    bulkDelete()
  }

  const handleNoClick= ()=>{
    setShowConfirmationModal(false)
  }

  const handleConfirmationModalState = () => {
    setShowConfirmationModal(false)

}

const handleBackClick = ()=>{
    navigate(-1)
    navigate(-1)
    navigate(-1)

}

const getAssetId = (data)=>{
    let obj = data.find(o => o.key === 'id');
    return obj.value;
}
      
// useEffect(()=>{
//     if(JSON.stringify(dateFilterColumn)!== "{}"){
//         if(dateFilterColumn.type === 'FY'){
//             var startDate = "";
//                   var endDate = "";
//                   var today = new Date();
//                   if ((today.getMonth() + 1) <= 3) {
//                     startDate = moment(new Date(today.getFullYear() - 1, 3, 1)).format('YYYY-MM-DD');
//                     endDate = moment(new Date(today.getFullYear(), 2, 31)).format('YYYY-MM-DD');
//                    // fiscalyear = (today.getDate() - 1) + "-" + today.getDate()
//                   } else {
//                     startDate = moment(new Date(today.getFullYear(), 3, 1)).format('YYYY-MM-DD');
//                     endDate = moment(new Date(today.getFullYear() +1, 2, 31)).format('YYYY-MM-DD');
                   
//                   }
//                   setStartDate(startDate)
//                   setEndDate(endDate)
//         }
//     }
// },[dateFilterColumn])


const handleStartDateChange = e =>{
    
        const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        setStartDate(newDate);
         setStartPageNumber('1')
        setEndPageNumber(process.env.REACT_APP_PAGE_NUMBER)
        sessionStorage.setItem('startDate', newDate)
      }

      const handleEndDateChange = e =>{
        const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        setEndDate(newDate);
        setStartPageNumber('1')
        setEndPageNumber(process.env.REACT_APP_PAGE_NUMBER)
        sessionStorage.setItem('endDate', newDate)
      }


    return (
        <>
            {isAuthorized ? <ErrorMessage></ErrorMessage> : 
            <div>
                {loadingExcel || loadingPdf || loading || isLoading ? <Loader></Loader> : null}
                <div className="Div_Style">
                    <div className="Inner_Div">
                        {reportActionsNames.includes('Back') ? <BackButton btnName='BACK' handleClick={handleBackClick}></BackButton> :null}
                        <h2>{JSON.stringify(reportInfo)!=="{}" ? reportInfo.aliasname + " » » ": ""}</h2>
                    
                        {reportActionsNames.includes('Excel') ?<Button btnName='EXCEL EXPORT' handleClick={handleExcelExportClick}></Button>:null}
                        {reportActionsNames.includes('Pdf') ?<Button btnName='PDF EXPORT' handleClick={handlePdfExportClick}></Button>:null}
                    {reportActionsNames.includes('Bulk Delete') ? <Button btnName='BULK DELETE' handleClick={handleBulkDeleteClick}></Button> :null}
                    {JSON.stringify(dateFilterColumn) !== "{}" ? <div className="Div_Style_2">
                        <label>From :</label>
                        <input type="date" className="Input_Style" value={startDate} onChange={handleStartDateChange}/>
                        <label>To :</label>
                        <input type="date" className="Input_Style" value={endDate} onChange={handleEndDateChange}/>
                    </div>: null}
                    </div>
                    
                    <ResetButton btnName='RESET' handleClick={handleResetClick}></ResetButton>
                </div>
                <div id ="Table_div" className="Table_Height">
                    <table className="TableHeader">
                        <thead >
                            <tr>
                            { reportActionsNames.includes('Bulk Delete') ? <th scope="col"><CheckBox id={'all'} checked={isAllChecked} handleChange={handleAllChecked}></CheckBox></th> : null }
                                {ImportedComponent !==null ? <th scope="col">Actions </th>:null}

                                {
                                    reportStartData.length > 0 ? reportStartData.map((data, index) => {

                                        if (hideColumnNameList.findIndex(x => x.columnname === data.key) < 0) {
                                            return ([<th key={index} scope="col" >{data.key} </th>, <ColumnResizer className="columnResizer" resizeEnd={(width) => handleChangeWidth(data.key, width)} defaultWidth={localStorage.getItem(data.key) !== null ? localStorage.getItem(data.key) : (data.key === 'Sr No' ? 70 
                                            : reportColumnType[data.key] === 'Int' ? 100 : 150)} />])

                                        }

                                    }
                                    ) : null
                                }
                            </tr>
                            <tr>
                            { reportActionsNames.includes('Bulk Delete') ? <th scope="col"></th> : null }
                            {ImportedComponent !==null ? <th scope="col"> </th>:null}
                            
                                {
                                    reportStartData.length > 0 ? reportStartData.map((data, index) => {

                                        if (hideColumnNameList.findIndex(x => x.columnname === data.key) < 0) {
                                            return ([<th>

                                                <input id={data.key} type="text" onChange={handleSearchInput} disabled={data.key === 'Sr No' ? true: false}/>
                                            </th>, <ColumnResizer className="columnResizer" />])
                                        }
                                    }
                                    ) : null
                                }

                            </tr>
                        </thead>
                        {
                            reportStartData.length > 0 ? (reportStartData[0].key === 'Sr No' && reportStartData[0].value === '' && searchInputQuery !== "" ? null :
                                <tbody>
                                    
                                    {
                                        reportData.length > 0 ? reportData.map((data, index) => {
                                            if (reportData.length === index + 1) {
                                                return <tr ref={lastElementRef} key={index}>
                                                    {reportActionsNames.includes('Bulk Delete') ? <td><CheckBox id={getAssetId(data)} checked={isAllChecked ? true : (checkedSrNo.includes(getAssetId(data)) === true ? true : false)} handleChange={(event)=>handleSingleCheckboxChange(event,data)}></CheckBox></td>  :null  }                                                {ImportedComponent !== null ? <ImportedComponent  index={index} data={data} queryParameters={queryParameters} onSuccess={handleSuccess} reportActions={reportActionsNames} reportActionsJs={reportActionsJs} startDate={startDate} endDate={endDate} onClose={handleCloseModal}></ImportedComponent> : null}
                                                    {
                                                        data.map((data1, index1) => {

                                                            if (hideColumnNameList.findIndex(x => x.columnname === data1.key) < 0) {
                                                                return ([<td key={index1} className={`${reportColumnType[data1.key] === 'Int' ? "text-right" : "text-left"}`}>{data1.value}</td>, <ColumnResizer className="columnResizer" />])

                                                            }
                                                        }
                                                        )
                                                    }
                                                </tr>
                                            }
                                            else {
                                                return <tr key={index}>
                                                    {reportActionsNames.includes('Bulk Delete') ?<td>
                                                        <CheckBox id={getAssetId(data)} checked={isAllChecked ? true : (checkedSrNo.includes(getAssetId(data)) === true ? true : false)} handleChange={(event)=>handleSingleCheckboxChange(event,data)}></CheckBox>
                                                       
                                                    </td>:null}                                          
                                                    {ImportedComponent !== null ? <ImportedComponent index={index} data={data} queryParameters={queryParameters} onSuccess={handleSuccess} reportActions={reportActionsNames} reportActionsJs={reportActionsJs} onClose={handleCloseModal}></ImportedComponent> : null}
                                                    {
                                                        data.map((data1, index1) => {

                                                            if (hideColumnNameList.findIndex(x => x.columnname === data1.key) < 0) {
                                                                return ([<td key={index1} className={`${reportColumnType[data1.key] === 'Int' ? "text-right" : "text-left"}`}>{data1.value}</td>, <ColumnResizer className="columnResizer" />])

                                                            }
                                                        }
                                                        )
                                                    }
                                                </tr>
                                            }
                                        })
                                            : null
                                    }
                                </tbody>) : null

                        }

                        {
                            reportActionsNames.includes('Total') ? (
                            <tfoot>
                                <tr >
                                 {reportActionsNames.includes('Bulk Delete') ?<td>&nbsp;
                                                        
                                                       
                                                    </td>:null}                                          
                                                    {ImportedComponent !== null ? <td>&nbsp;</td> : null }    
                                {
                                   
                                   Object.entries(reportColumnType).map(([key, value]) => (
                                    reportTotalColumnsData[key]!==undefined ? 
                                    (hideColumnNameList.findIndex(x => x.columnname === key) < 0 ?  [<td className="text-right font-bold" key={key}>{reportTotalColumnsData[key]}</td>, <ColumnResizer className="columnResizer" />]: null)
:  (key === 'Sr No' ? [<td className="text-right font-bold">Total</td>, <ColumnResizer className="columnResizer" />] : (
    hideColumnNameList.findIndex(x => x.columnname === key) < 0 ?  [<td key={key}>&nbsp;</td>, <ColumnResizer className="columnResizer" />] : null))


    
))
                                    // Object.keys(reportColumnType).map((key) => {
                                    //     if (reportTotalColumnsData[key]!==undefined)
                                    //         {
                                                 
                                    //             <td>{'View'}</td>
                                    //         }
                                    //         else{
                                    //             <td>View</td>
                                    //         }
                                        
                                    // })
                                }
                                </tr>
                                
                            </tfoot> ) : null
                        }


                    </table>
                </div>
                <Modal open={showModal} setModalState={handleModalState} message={modalMessage} success={isSuccess}></Modal>
                <ConfirmationModal open={showConfirmationModal} setModalState={handleConfirmationModalState} message={confirmationModalMessage} success={isConfirmationSuccess} onClickYes={handleYesClick} onClickNo={handleNoClick}></ConfirmationModal>
            </div>}
        </>
    )
}


export default Report;