import React, { useEffect } from "react";

function CheckBox({id,checked, handleChange}){

  useEffect(()=>{
    console.log(checked)
  },[checked])

    return(
        <input
        id={id}
          type="checkbox"
          checked={checked}
          onChange={handleChange}
        />
        
    )
}

export default CheckBox;