import React, { useEffect, useState } from "react"
import axios from "axios";
import Button from "../Components/Button";
import InputTextField from "../Components/InputTextField";
import TextArea from "../Components/TextArea";
import Label from "../Components/Label";
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import actions from '../store/actions'
import CheckBox from "../Components/CheckBox";
import AutoCompleteExtender from "../Components/AutoCompleteExtender";
function ReportsList() {

    const [reportsInfo, setReportsInfo] = useState([]);
    const [isNewReportFormActive, setIsNewReportFormActive] = useState(false)

    const [reportNameInputValue, setReportNameInputValue] = useState('')
    const [queryNameInputValue, setQueryNameInputValue] = useState('')
    const [reportAliasNameInputValue, setReportAliasNameInputValue] = useState('')
    const [queryInputValue, setQueryInputValue] = useState('')
    const [execQueryInputValue, setExecQueryInputValue] = useState('')
    const [messageValue, setMessageValue] = useState('')
    const [reportEditData, setReportEditData] = useState({ id: 0, name: '', query: '', aliasname:'' })
    const [queryResultData, setQueryResultData] = useState([])
    const [reportFilterColumn, setReportFilterColumn] = useState([])
    const [isFilterChecked, setIsFilterChecked] = useState(false)
    const [hideColumnNameList, setHideColumnNameList] = useState([])
    const [reportColumnNames, setReportColumnNames] = useState([])
    const [selectedColumnName, setSelectedColumnName] = useState('')
    const [selectedOption, setSelectedOption] = useState("This Month")  

    const params = new useParams()
    
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actions.setQueryParamters({'@database': params.database, '@locationid': params.locationid, '@userid':params.userid, '@userrole':params.userrole,'@username':params.username, '@companyid':params.companyid}))
        fetchReportInfo()
    }, [])

    useEffect(() => {
        if (reportEditData.id !== 0) {
            editReportData(reportEditData)
        }

    }, [reportEditData])

    const fetchReportInfo = async () => {
        console.log('inside gere',params.database)
        await axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/GetReportInfo',
            headers: {
                'database': params.database,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                console.log('inside gere')
                setReportsInfo(response.data)
            })
            .catch(err => {
                // Handle errors
                console.error(err);
            });



    }

    const createOrUpdateProcedure = async () => {
        console.log(queryInputValue)
        await axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/CreateAlterSP',
            data: {
                query: queryInputValue
            }, // you are sending body instead
            headers: {
                'database': params.database,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                //setQueryResultData(response.data.mData)
                setMessageValue(response.data)
                console.log('run query reponse',response)
            })
            .catch(err => {
                // Handle errors
                setMessageValue(err.response.data)
                
            });
    }

    const execQuery = async () => {
        setMessageValue('Executing Query...')
        await axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/ExecuteQuery',
            data: {
                query: execQueryInputValue
            }, // you are sending body instead
            headers: {
                'database': params.database,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                if(response.data.responseCode === "200"){
                    console.log(response)
                    setQueryResultData(response.data.mData)
                    const arr = []
                    response.data.mData[0].map((data, index)=>{
                        arr.push(data.key)
                    })
                    setReportColumnNames([...arr])
                    setMessageValue(response.data.responseMessage)
                }
                else{
                    setMessageValue(response.data.responseMessage)
                }
                
            })
            .catch(err => {
                // Handle errors
                console.error(err);
            });
    }

    const insertReportData = async (request) => {
        setMessageValue('Saving Report Data...')
        await axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/PostReportInfo',
            data: {
                id: reportEditData.id,
                name: reportNameInputValue,
                query: queryNameInputValue,
                aliasname: reportAliasNameInputValue
            },
            headers: {
                'database': params.database,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                console.log('response')
                setMessageValue(response.data)
                insertHideColumnName()
                insertFilterColumnName()
               
            })
            .catch(err => {
                // Handle errors
                setMessageValue(err.response.data)
                console.error(err);
            });
    }

    const insertFilterColumnName = async () => {
        let obj = {}
        if(isFilterChecked === true){
            obj =    {
              
                "id": 0,
                "reportid": reportEditData.id,
                "columnname": selectedColumnName,
                "type": selectedOption
              }
         
        }
        else{
            obj =    {
                "id": 0,
                "reportid": reportEditData.id,
                "columnname": '',
                "type": ""
              }
         
        }
        console.log(obj)
        
        await axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/PostFilterColumnNames',
            data: obj,
            headers: {
                'database': params.database,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                console.log(response)
                setMessageValue("Data saved successfully")
                console.log(response)
            })
            .catch(err => {
                // Handle errors
                console.error(err);
            });
    }

    const insertHideColumnName = async () => {
        const request = [];
        hideColumnNameList.map((data, index) => {
            request.push({ reportid: reportEditData.id.toString(), columnname: data })
        })
        
        await axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/PostColumnNames',
            data:
            {

                "list": request
            },
            headers: {
                'database': params.database,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                setMessageValue("Data saved successfully")
                console.log(response)
            })
            .catch(err => {
                // Handle errors
                console.error(err);
            });
    }

    const editReportData = async (request) => {
        getFilterColumnNames()
        getCheckedColumnsNames();
        await axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/EditReport',
            data: request,
            headers: {
                'database': params.database,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                setIsNewReportFormActive(true)
                setReportNameInputValue(request.name)
                setQueryNameInputValue(request.query)
                setReportAliasNameInputValue(request.aliasname)
                setQueryInputValue(response.data.queryText)
                setExecQueryInputValue(response.data.queryExec)

            })
            .catch(err => {
                // Handle errors
                console.error(err);
            });
    }


    const getFilterColumnNames = async()=>{
        await axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/GetFilterColumnNames?reportid=' + reportEditData.id,
            data: {},
            headers: {
                'database': params.database,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                if(response.data.length > 0){
                    setReportFilterColumn(response.data)
                    setSelectedColumnName(response.data[0].columnname)
                    setIsFilterChecked(true)
                    console.log('response',response)
                }
                else{
                    setIsFilterChecked(false)
                    setReportFilterColumn([])
                    console.log('response',response)
                }
                
            })
            .catch(err => {
                // Handle errors
                console.error(err);
            });
    }

    const getCheckedColumnsNames = async () => {
        console.log('report id', {
            reportid: reportEditData.id
        },)
        await axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/GetColumnNames?reportid=' + reportEditData.id,
            data: {},
            headers: {
                'database': params.database,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                response.data.map((data, index) => {
                    hideColumnNameList.push(data.columnname)
                })
                //console.log('response',response)
            })
            .catch(err => {
                // Handle errors
                console.error(err);
            });
    }

    const handleAddNewReportBtnClick = () => {
        setReportEditData({ id: 0, name: '', query: '', aliasname:'' })
        setIsNewReportFormActive(true)
    }

    const handleRunQueryBtnClick = () => {
        if(queryInputValue === ''){
            setMessageValue('Please enter create or update query')
            return;
        }
        createOrUpdateProcedure();
    }



    const handleExecQueryBtnClick = () => {
        if(execQueryInputValue === ''){
            setMessageValue('Please enter query')
            return;
        }
        execQuery();
    }

    const handleSaveBtnClick = () => {
        if(reportNameInputValue === '' || queryNameInputValue === '' || reportAliasNameInputValue === ''){
            setMessageValue('Please enter report name or query name')
            return;
        } 
        if (reportEditData.id === 0) {
            insertReportData({
                id: 0,
                name: reportNameInputValue,
                query: queryNameInputValue,
                aliasname: reportAliasNameInputValue
            });

        }
        else {
            insertReportData(reportEditData);
        }


    }

    const handleCancelBtnClick = () => {
        setIsNewReportFormActive(false)
        setReportNameInputValue('')
        setQueryNameInputValue('')
        setQueryInputValue('')
        setQueryResultData([])
        fetchReportInfo();
        setMessageValue('')
        setExecQueryInputValue('')

    }

    const handleReportNameChange = (value) => {
        setReportNameInputValue(value)
    }

    const handleQueryNameChange = (value) => {
        setQueryNameInputValue(value)
    }

    const handleReportAliasNameChange = (value) => {
        setReportAliasNameInputValue(value)
    }

    const handleQueryChange = (value) => {
        setQueryInputValue(value)

    }

    const handleExecQueryChange = (value) => {
        setExecQueryInputValue(value)
    }

    const handleEditClick = (data) => {

        setReportEditData({ ...data })


    }

    const handleDeleteClick = async (data) => {

        await axios({
            method: 'delete',
            url: process.env.REACT_APP_API_URL + '/DeleteReportInfo',
            data:
            {
                id: data.id,
                name: data.name,
                query: data.query
            },
            headers: {
                'database': params.database,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                fetchReportInfo();
                console.log(response)
            })
            .catch(err => {
                // Handle errors
                console.error(err);
            });


    }

    const handleCheckboxChange = (event) => {
        if (event.target.checked === true) {
            if (hideColumnNameList.some(value => value.toLowerCase() === event.target.value.toLowerCase())) {

            }
            else {
                setHideColumnNameList([...hideColumnNameList, event.target.value])

            }
        }
        else {
            if (hideColumnNameList.some(value => value.toLowerCase() === event.target.value.toLowerCase())) {
                var filteredArray = hideColumnNameList.filter(e => e !== event.target.value)
                setHideColumnNameList(filteredArray)
            }

        }
    }

    const handleFilterCheckboxChange = ()=>{
        setIsFilterChecked(!isFilterChecked)
    }

    const handleColumnNameSelection = (value)=>{
        setSelectedColumnName(value)
    }

    function onValueChange(event){
        // Updating the state with the selected radio button's value
        setSelectedOption(event.target.value)
    }

  

    return (
        <div className="ml-3 mt-2">
            {isNewReportFormActive === false ?
                <div>
                  
                    <Button handleClick={handleAddNewReportBtnClick} btnName="Add New Report"></Button>
                    <table className="border-collapse border border-slate-400 w-[40%]">
                        <thead className="text-white" style={{ background: '#3c8dbc' }}>
                            <th className="border border-slate-400 p-3">Sr.No</th>
                            <th className="border border-slate-400 p-3">Report Name</th>
                            <th className="border border-slate-400 p-3">Actions</th>
                        </thead>
                        <tbody className="text-center">
                            {
                                reportsInfo.map((data, index) =>
                                    <tr>
                                        <td className="border border-slate-400 p-3">{index + 1}</td>
                                        <td className="border border-slate-400 p-3">{data.name}</td>
                                        <td className="border border-slate-400 p-3">
                                            <button className="bg-green-600 px-5 rounded text-white font-medium mx-1 my-1" onClick={() => handleEditClick(data)}>{'Edit'}</button>
                                            <button className="bg-red-600 px-5 rounded text-white font-medium mx-1 my-1" onClick={() => handleDeleteClick(data)}>{'Delete'}</button>
                                            <Link to="/Report" state={{ reportData: data}}>
                                                
                                            <button className="bg-orange-600 px-5 rounded text-white font-medium mx-1 my-1" >{'Open Report'}</button>
                                            
                                            </Link>
                                           
                                        </td>

                                    </tr>
                                )
                            }

                        </tbody>
                    </table>
                </div> :
                <div>
                    <Label label='Report Name'></Label>
                    <InputTextField handleOnChange={handleReportNameChange} value={reportNameInputValue}></InputTextField>
                    <Label label='Query Name'></Label>
                    <InputTextField handleOnChange={handleQueryNameChange} value={queryNameInputValue}></InputTextField>
                    <Label label='Report Alias Name'></Label>
                    <InputTextField handleOnChange={handleReportAliasNameChange} value={reportAliasNameInputValue}></InputTextField>
                    <div className="mt-1">
                        <Label label='Query'></Label>
                        <TextArea handleOnChange={handleQueryChange} value={queryInputValue}></TextArea>
                        <Label label='Execute query'></Label>
                        <TextArea handleOnChange={handleExecQueryChange} value={execQueryInputValue}></TextArea>
                        <div className="grid grid-cols-6 my-4 gap-2 px-4"> 
                        {
                            queryResultData.length > 0 ? queryResultData[0].map((data, index) =>
                                <div key={index} className="flex px-5 gap-10 py-1 border-[1px] border-solid border-gray-400 rounded">
                                    <input value={data.key} type="checkbox" checked={hideColumnNameList.some(value => value.toLowerCase() === data.key.toLowerCase()) ? true : false} onChange={handleCheckboxChange} />
                                    <span>{data.key}</span>
                                </div>
                            ) : null
                        }
                        </div>
                    </div>
                    { queryResultData.length > 0 ? <div>
                        <CheckBox checked={isFilterChecked} handleChange={handleFilterCheckboxChange}></CheckBox>
                        {isFilterChecked ? <div>
                            <AutoCompleteExtender itemsList = {reportColumnNames} placeholder='
                            Column name' defaultValue={selectedColumnName} setColumnName={handleColumnNameSelection}></AutoCompleteExtender>
                            <div>
                                 {/* Radio button for "Male" */}
                                <label>
                                <input
                                    type="radio"
                                    value="This Month"
                                    // Checking this radio button if the selected option is "Male"
                                    checked={selectedOption === "This Month"}
                                    onChange={onValueChange}/>
                                This Month
                                </label>
                                
                                {/* Radio button for "Female" */}
                                <label>
                                <input
                                    type="radio"
                                    value="Financial Year"
                                    // Checking this radio button if the selected option is "Female"
                                    checked={selectedOption === "Financial Year"}
                                    onChange={onValueChange}/>
                                Financial Year
                                </label>
                               
                            </div>
                        </div> : null}
                    </div> : null}
                    <Button handleClick={handleRunQueryBtnClick} btnName="Run Query"></Button>
                    <Button handleClick={handleExecQueryBtnClick} btnName="Execute Procedure"></Button>
                    <Button handleClick={handleSaveBtnClick} btnName="Save"></Button>
                    <Button handleClick={handleCancelBtnClick} btnName="Cancel"></Button>
                    <span>{messageValue}</span>
                    <div className="overflow-auto h-88">
                        <table id="table" className="table-auto bg-[white] rounded-md shadow-md w-full">
                            <thead className="sticky -top-1 order-1 border-solid border-gray-400 text-sm capitalize border-2 text-slate-100" style={{ background: "rgb(54, 128, 171)" }}>
                                <tr>
                                    {
                                        queryResultData.length > 0 ? queryResultData[0].map((data, index) =>
                                            <th scope="col" className={'px-5 py-2 border-[1px] border-solid border-gray-400 w-[100px]'}>{data.key}</th>
                                        ) : null
                                    }
                                </tr>
                            </thead>
                            <tbody className="">
                                {
                                    queryResultData.length > 0 ? queryResultData.map((data, index) =>
                                        <tr>
                                            {
                                                data.map((data1, index1) =>
                                                    <td className="px-5 py-2 border-[1px] border-solid border-gray-400 w-[100px]" >{data1.value}</td>
                                                )
                                            }

                                        </tr>


                                    ) : null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    )
}


export default ReportsList