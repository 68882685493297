import React from "react";


const ErrorMessage = () => {
    return (
        <>
        <div className="box">
            <h2>Unauthorized Access</h2>
        </div>
            <div className="Error_Msg">
                <h2>You are not authorized to view this page.</h2>
            </div>
        </>
    )
}

export { ErrorMessage }