import React, { useEffect } from 'react'
import { useState } from "react"
import axios from "axios"
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style' 

export default function useExcelExport( spName,queryParameters) {

  const [startPageNumber, setStartPageNumber] = useState('1')
  const [endPageNumber, setEndPageNumber] = useState(process.env.REACT_APP_PAGE_NUMBER)

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [excelReportData, setExcelReportData] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [isDownloadExcel, setIsExcelDownload] = useState(false)

  

  const fetchReportQuery = async() =>{
        await axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/OpenReport',
            data: {
                query:spName,
                parameters:{
                     '@startPageNumber':startPageNumber.toString(),
                    '@endPageNumber': endPageNumber.toString(),
                     '@filterName': '',
                '@filterValue': '',
                 ...queryParameters
                    
                },
               
            },
            headers: {
                'database': process.env.REACT_APP_DATABASE_NAME,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
              console.log('inside fetch query')
                execSp(response.data.queryExec)
               

            })
            .catch(err => {
               
                console.error(err);
            });
    
    }


    const execSp =(spQuery)=>{
    
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL + '/ExecuteQuery',
      data: {
        query: spQuery
      }, 
      headers: {
        'database': process.env.REACT_APP_DATABASE_NAME,
        'Content-Type': 'application/json'
      },
    })
    .then(response => {
      console.log('inside exce function call')
      const arr = response.data.mData[0];
      if(arr[0].key === 'RowNum' && arr[0].value===''){
        setHasMore(false)
        console.log('isnide gere')
        exportToExcel()
      }
      else{
        let arr = [];
        response.data.mData.map((data,index)=>{
          let obj = {};
          data.map((data1,index1)=>{
            obj[data1.key] = data1.value
          })
          arr = [...arr, obj]
        })
        setStartPageNumber(parseInt(endPageNumber) +1)
        setEndPageNumber( parseInt(endPageNumber) + parseInt(process.env.REACT_APP_PAGE_NUMBER))
        console.log(arr)
        setIsExcelDownload(false)
        setExcelReportData(prev =>{
          return [...prev , ...arr]})
        setHasMore(true)
       
      }
     
     
     
     
    })
    .catch(err => {
      setError(true)
      setLoading(false)
      console.error(err);
    });
    }

    useEffect(()=>{
      setLoading(true)
    },[])

    useEffect(()=>{
      
      if(hasMore === true ){
        console.log('inside excel export')
         fetchReportQuery();
      }
      else{
        console.log('inside has more dfalse')
        setLoading(false)
       
      }
     
    },[hasMore])

    // useEffect(()=>{
    //   if(isDownloadExcel === true){
    //     exportToExcel()
    //   }
    // },[isDownloadExcel])

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
const fileExtension = '.xlsx';
const exportToExcel = async () => {
  console.log('inside excel export file')
const ws = XLSX.utils.json_to_sheet (excelReportData);
const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array' });
const data = new Blob ([excelBuffer], {type: fileType });
FileSaver.saveAs (data, 'Asset List' + fileExtension);
}


  return { excelReportData , execute: fetchReportQuery }
}
