import React from "react";

function Button({handleClick, btnName}){
    return(
        <button  className="Button_Style" onClick={handleClick}>{btnName}</button>
        
    )
    
}

export default Button;